<template>
  <div class="common_box">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  methods: {},
  destroyed: function () {
    window.location.reload();
  },
  mounted: function () {
    var ymwl = {};
    ymwl.business_id = 3; // 2数据安全培训  3BBS商学院 4依智山东大区
    window.ymwl = ymwl;
    // 创建script标签，引入外部文件
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'http://cs.yizhivet.com/assets/layer/ymwl_common.js?v=1662001397'
    document.getElementsByTagName('head')[0].appendChild(script)
  },
}
</script>
